@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.App {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

section {
    border-radius: 20px;
}

.Button {
    color: white;
    background-color: blue;
    padding: 10px;
    width: 100px;
    font-weight: 800;
    letter-spacing: 2;
    cursor: pointer;
    border-radius: 10px;
    transform: all 1s cubic-bezier(0.42, 0, 0.17, 0.71);
    margin: 10px;
}

.Button:hover {
    background-color: green;
}

.dialog {
    padding: 20px;
    background: white;
    position: absolute;
    z-index: 500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(37, 37, 37, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-content {
    background-color: white;
    margin: 0;
    width: 400px;
    height: 400px;
    display: flex;
    padding: 20px;
    flex-direction: column;
    border-radius: 10px;
    align-items: flex-start;
    justify-content: space-between;
    overflow-y: auto;
}

.close {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
}

.close>button {
    height: 36px;
    width: 36px;
    border: none;
    background: transparent;
    cursor: pointer;
}

.title {
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
    padding-bottom: 5px;
}

@keyframes anime {
    0% {
        font-size: 10px;
    }
    30% {
        font-size: 15px;
    }
    100% {
        font-size: 12px;
    }
}

.detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    height: 40px;
    border-bottom: 1px dashed;
    animation: slidein 2s;
}

.detail-first-child {
    border-top: 1px dashed;
    margin-top: 20px;
}

.detail-header {
    font-weight: bold;
    font-size: 1rem;
}

.detail-content {
    font-size: 1rem;
    padding-left: 5px;
}

.green {
    color: rgb(153, 255, 0);
}

.description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
}

.red {
    color: green;
}

.message {
    font-size: 0.8rem;
}

.small {
    font-size: 0.9rem;
}

@keyframes slidein {
    from {
        margin-left: 100%;
        width: 300%;
    }
    to {
        margin-left: 0%;
        width: 100%;
    }
}

.errorMessage {
    display: flex;
    align-content: center;
    align-items: center;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body {
    background-color: hsl(0, 0%, 96%);
}

.img-fluid {
    height: "100px";
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.background-radial-gradient {
    background-color: #F2F3F5;
}

.bg-glass {
    background-color: hsla(0, 0%, 100%, 0.9) !important;
    backdrop-filter: saturate(200%) blur(25px);
}

.mt-40 {
    padding-top: 12vh;
    margin-top: 12vh;
}

.text-charging {
    padding-top: 15vh;
    margin-top: 15vh;
    margin-top: 50px;
}

.mt-50 {
    padding-top: 15vh;
    margin-top: 15vh;
}

#header-with-border .container {
    text-align: center;
    padding: 45px 20px;
}

#header-with-border .container h3 {
    display: inline-block;
    background-image: url(http://gdurl.com/evtq), url(http://gdurl.com/vV5B), url(http://gdurl.com/vNld), url(http://gdurl.com/XWi1);
    background-repeat: no-repeat;
    background-position: top left, top right, bottom left, bottom right;
    font-size: 45px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 5px;
}

#header-with-border .container a {
    display: inline-block;
    font-size: 45px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 5px;
}

#header-with-border .container a:hover {
    background-image: url(http://gdurl.com/evtq), url(http://gdurl.com/vV5B), url(http://gdurl.com/vNld), url(http://gdurl.com/XWi1);
    background-repeat: no-repeat;
    background-position: top left, top right, bottom left, bottom right;
}

.camerabackgroud {
    background-image: url(https://terra-admin-site-pro.s3.ap-northeast-1.amazonaws.com/india_images/98c53900-ca32-4e65-8f4b-c71efa514444.jpeg);
    background-repeat: no-repeat;
    background-position: top left, top right, bottom left, bottom right;
}

.mt-5 {
    padding-top: 1vh;
    margin-top: 1vh;
}

.rounded-pill {
    background-color: #1266f1;
    border: none;
    color: white;
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    border-radius: 8px;
    cursor: pointer;
}

.rounded-back {
    background-color: white;
    border: none;
    color: black;
    width: 100%;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    border: 1px solid #D8DBDE;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.19);
}

.m-5 {
    padding: 1rem;
}

.mt-10 {
    padding-top: 5vh;
    margin-top: 5vh;
}

@media screen and (max-width: 1000px) {
    #title_message {
        visibility: hidden;
        clear: both;
        float: left;
        margin: 0;
        width: 0px;
        height: 0px;
        display: none;
    }
    #space {
        margin: 45px;
        padding: 10px;
    }
    .position-relative {
        width: 100%;
        height: 100%;
    }
    #mobileview {
        border: "0px";
    }
}

video,
canvas,
div {
    border-radius: 20px;
}

.footer-bottom {
    width: 100%;
    padding: 5px 0;
    text-align: center;
    color: black;
}

.colorblk {
    color: black!important;
    font: bolder;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: green;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    display: block;
    stroke-width: 6;
    stroke: green;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .5s both;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .5s both;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.25, 0, 0.15, 1) .6s forwards;
    -webkit-animation: stroke 0.3s cubic-bezier(0.25, 0, 0.15, 1) .6s forwards;
}

svg {
    overflow: visible;
}

@-webkit-keyframes stroke {
    100% {
        -webkit-stroke-dashoffset: 0;
    }
}

@-webkit-keyframes stroke {
    0%,
    100% {
        -webkit-transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
    }
}

@-webkit-keyframes stroke {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 30px #fff;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #fff;
    }
}